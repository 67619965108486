<!-- 
        导航管理
-->
<template>
    <div class="box">
        <div class="leftTreeBox">
            <div class="radio" style="display: flex; align-items: center;">
                <el-select style="width: 100px;" v-model="wechat" class="m-2" placeholder="Select" @change="handWechat">
                    <el-option label="零售" value="零售" />
                    <el-option label="回收" value="回收"/>
                </el-select>
                <el-select style="width: 100px;" v-model="radio" class="m-2" placeholder="Select" @change="handRadio">
                    <el-option label="全新" value="全新" />
                    <el-option label="二手" value="二手"/>
                </el-select>
                <el-button style="float: right; margin-left: 10px;" @click="Refresh" link type="primary" size="small">一键刷新</el-button>
            </div>
            
            <div style="height: 10px;"></div>
            <el-input placeholder="输入关键字进行过滤" v-model="filterText" clearable></el-input>
            <div style="height: 10px;"></div>
            <div style="overflow-y:scroll;overflow-x:hidden;height: calc(100% - 180px)">
                <el-tree :data="navData"  v-loading="treeLoading" :props="defaultProps" ref="tree"  @node-click="handleNodeClick" :filter-node-method="filterNode"></el-tree>
            </div>
            
        </div>
        <div class="seg"></div>
        <div class="rightBox">
            <!-- 价格数据 -->
            <div class="priceBox">
                <el-card class="box-card" style="width: 100%; height: calc(100% - 50px);">
                    <div class="clearfix">
                        <span style="float: left; font-weight: 600; color: #F56C6C; text-overflow: ellipsis; white-space: normal;word-break: break-all;">● {{ priceTitle }}</span>
                        <el-button style="float: right; margin-right: 20px; padding: 0px 20px"  @click="addCategory" type="primary" plain>
                        <el-icon><Plus/></el-icon>
                        </el-button>

                    </div>
                    <div style="height: 50px;"></div>
                    <!-- 表格 -->
                    <div class="Infos">
                       <el-table :data="tableData" v-loading="loading" style="width: 100%;" height="550">

                            <el-table-column label="名称">
                                <template #default="scope">
                                    <el-input placeholder="请输入名称" v-model="scope.row.name"></el-input>
                                </template>
                            </el-table-column>
                             <el-table-column label="状态" width="80">
                                <template #default="scope">
                                    <el-tag type="info" v-if="(scope.row.state == 0)">暂停中</el-tag>
                                    <el-tag type="success" v-else-if="(scope.row.state == 1)">正常</el-tag>
                                    <el-tag type="danger" v-else>封禁</el-tag>

                                </template>
                            </el-table-column>
                            <el-table-column label="权重排序" width="100">
                                <template #default="scope">
                                    <el-input placeholder="输入数字" v-model="scope.row.weight"></el-input>
                                </template>
                            </el-table-column>
                             <el-table-column fixed="right" label="操作" width="150">
                                <template #default="scope">
                                    <el-button link type="primary" size="small"  @click="updateElement(scope.row.id,0)"  v-if="(scope.row.state == 1)">暂停</el-button>
                                    <el-button link type="success" size="small"  @click="updateElement(scope.row.id,1)"  v-else>启用</el-button>
                                    <el-button link type="danger" size="small"  @click="delElement(scope.row.id)">删除</el-button>
                                    <el-button link type="primary" size="small"  @click="updateElement(scope.row.id,scope.row.name,scope.row.weight)">更新</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </el-card>
            </div>
            <!-- 添加 -->
            <el-dialog v-model="dialogAddBox" align-center title="添加类目" width="20%">
                <el-input v-model="priceTitle" disabled>
                    <template #prepend>所属类目</template>
                </el-input>
                <div style="height: 20px;"></div>
                 <el-form :model="form" ref="form" :rules="rules">
                    <el-form-item prop="name">
                        <el-input v-model="form.name" placeholder="请输入类目名"  autocomplete="off" clearable></el-input>
                    </el-form-item>
                 </el-form>

                <template #footer>
                    <span class="dialog-footer">
                        <el-button type="primary" @click="addBtn">确认</el-button>
                    </span>
                </template>
            </el-dialog>
        </div>
    </div>
</template>


<script>
import Host from "../store/host";
export default {
    data(){
        return {
            filterText: '',
            navData: [],
            tableData: [],
            defaultProps: {
            children: 'children',
            label: 'label'
            },
            wechat: "零售",
            radio: '全新', // 单选框
            priceBox: false, // 显示
            priceTitle: "全新",
            att: "全新",
            cid: null,
            loading: true,
            treeLoading: true,
            dialogAddBox: false, // 添加对话框
            form: {
                name: ''
            },
            rules: {
                name:[
                    { required: true, message: '类目名不能为空', trigger: 'blur' },
                    { min: 1, max: 20, message: '类目名长度在 1 到 10 个字符', trigger: 'blur' }
                ]     
            }
        }
    },
    watch: {
      filterText(val) {
        this.$refs.tree.filter(val);
      }
    },
    methods: {
        filterNode(value, data) {
            if (!value) return true;
            return data.label.indexOf(value) !== -1;
        },
        // 树形导航
        handleNodeClick(e,a,i){
            console.log(i)
            this.att = e.att;
            this.priceTitle = e.label;
            this.cid =  e.id;
            this.getNav()
        },
        handWechat(val){
            if(val == '回收'){
                this.priceTitle = "全新";
                this.att = "全新";
                this.radio = "全新";
            } else {
                this.priceTitle = "全新";
                this.att = "全新";
            }
            this.treeLoading = true;
            this.getNav()
        },
        // 单选框
        handRadio(val){
            this.priceTitle = val;
            this.radio = val;
            this.att = val;
            this.treeLoading = true;
            this.getNav()
        },
        // 添加类目
        addCategory(){
            this.dialogAddBox = true;
        },
        // 添加确认
        addBtn(){
            this.$refs.form.validate(async valid => {
                if(!valid) return false;
                // 添加
                let tc = this.$message({
                    message: '加载中',
                    type: 'info',
                    duration: 0
                });
                this.dialogAddBox = false;
                let postUrl;
                if(this.wechat == "零售"){
                    postUrl = '/api/addNav';
                } else postUrl = '/recycleApi/addNav';
                let data;
                switch(this.att){
                    case "全新":
                       data = {type:1,name: this.form.name,weight: this.tableData.length,brand:this.att};
                       break;
                    case "二手":
                       data = {type:1,name: this.form.name,weight: this.tableData.length,brand:this.att};
                       break;
                    case "brand":
                       data = {type:2,name: this.form.name,weight: this.tableData.length,cid:this.cid};
                       break;
                    case "second":
                       data = {type:3,name: this.form.name,weight: this.tableData.length,cid:this.cid};
                       break;
                    default : return;
                }
                let resolveData = await Host.HostPost(postUrl,data);
                 tc.close();
                if(resolveData.code !== 200) return this.$message({message: '添加失败',type: 'danger'});
                // 二次刷新
                this.getNav();
                this.$message({message: '添加成功',type: 'success'});
                this.form.name = "";
            });
        },
        // 一键刷新
        Refresh(){
            this.att = this.radio;
            this.priceTitle = this.radio;

            this.cid = null;
            this.getNav();
        },
        // 获取列表
        async getNav(){
            Host.load = true;
            this.loading = true;
            let postUrl;
            if(this.wechat == "零售"){
                postUrl = '/api/getNav?att=' + this.att + '&cid=' + this.cid + '&any=false';
            } else postUrl = '/recycleApi/getNav?att=' + this.att + '&cid=' + this.cid + '&any=false';
            let resolveData = await Host.HostGet(postUrl);
            if(resolveData.code !== 200){
                this.loading = false;
                this.$message({message: '警告哦，这是一条警告消息',type: 'warning'});
                return;
            }
            if(this.att == "全新" || this.att == "二手"){
                this.navData = resolveData.data[0];
                this.tableData = resolveData.data[1];
            } else {this.tableData = resolveData.data;}
            this.loading = Host.load;
            this.treeLoading = Host.load;
        },
        // 删除报价导航
        delElement(id){
            let att = null;
            if(this.att == '全新' || this.att == "二手"){
                att = "brand";
            } else if(this.att == "brand"){
                att = "second";
            } else if(this.att == "second"){
                att = "config";
            }
            this.$confirm('此操作将永久删除关联类目的数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                let tc = this.$message({
                    message: '加载中',
                    type: 'info',
                    duration: 0
                });
                if(att == null) return;
                // 提交
                let postUrl;
                if(this.wechat == "零售"){
                    postUrl = '/api/setPrice/del';
                } else postUrl = '/recycleApi/setPrice/del';
                let data = {att: att,id: id};
                let resolveData = await Host.HostPost(postUrl,data);
                 tc.close();
                if(resolveData.code !== 200) return this.$message({message: '添加失败',type: 'danger'});
                this.getNav();
                this.$message({message: '删除成功',type: 'success'});
            }).catch(() => {});
        },
        // 修改报价导航
        async updateElement(id,type,weight){
            let tc = this.$message({
                message: '加载中',
                type: 'info',
                duration: 0
            });
            let att = null;
            if(this.att == '全新' || this.att == "二手"){
                att = "brandlevel";
            } else if(this.att == "brand"){
                att = "secondlevel";
            } else if(this.att == "second"){
                att = "configlevel";
            }
            if(att == null) return;
            // 提交
            let postUrl;
                if(this.wechat == "零售"){
                    postUrl = '/api/setPrice/update';
                } else postUrl = '/recycleApi/setPrice/update';
            let data = {att: att,id: id,type: type,weight: weight};
            let resolveData = await Host.HostPost(postUrl,data);
            tc.close();
            if(resolveData.code !== 200) return this.$message({message: '失败',type: 'danger'});
            this.getNav();
            this.$message({message: '修改成功',type: 'success'});
        },

    },
    created(){
        this.getNav();
    }
}
</script>

<style>
    .box {
        display: flex;
        justify-items: center;
    }
    .leftTreeBox {
        width: 30vw;
        margin: 10px;
    }
    .seg {
        width: 2px;
        background: #F2F6FC;
    }
    .rightBox {
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .priceBox {
        width: 50vw;
        margin: 20px;
        display: flex;
        /* justify-self: unset; */
    }
    .box-card {
        box-shadow: none !important;
        border: none !important;
    }
    /* 排序icon按钮 */
    .weightHove {
        border-radius: 100px;
        margin: 5px;
    }
    .weightHove:hover {
        cursor: pointer;
        color: #F56C6C;
    }
</style>