<!--

        协议管理

-->
<template>
    <div class="box">
        <div style="width: 100%; height: 50px;">
            <el-tabs v-model="label" type="border-card"  @tab-change="btnPane">
                <el-tab-pane label="用户协议" name="yh"></el-tab-pane>
                <!-- <el-tab-pane label="隐私协议" name="ys"></el-tab-pane>
                <el-tab-pane label="企业协议" name="sj"></el-tab-pane> -->
                <el-tab-pane label="关于我们" name="gy"></el-tab-pane>
                <!-- <el-tab-pane label="报单教程设置" name="salesman"></el-tab-pane> -->

                 <!-- 编辑器 -->
                <div class="ckeditorBox">
<!--                    <div class="iframeBox" v-loading="ckeditorLoading" v-show="ckeditorLoading"></div>-->
                    <div class="iframeBox" v-show="!ckeditorLoading" v-loading="textLoading">
                        <iframe ref="iframe" style="width: 100%; height: 100%;"  src="static/textare.html" frameborder="0" @load="onLoadIframe"></iframe>
                    </div>
                    <div v-show="ckeditorLoading">
                        <el-form :model="salesmanData" label-width="auto" style="max-width: 600px">
                            <el-form-item label="提示">
                                <el-input v-model="salesmanData.weChat" />
                            </el-form-item>
                            <el-form-item label="PDF链接">
                                <el-input v-model="salesmanData.phone" />
                            </el-form-item>

                            <el-form-item>
                                <el-button type="primary" @click="onSubmit">更改</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div class="buttomBox" v-show="!ckeditorLoading">
                      <el-button class="UpBtn" @click="saveContent" type="primary">更新协议</el-button>
                    </div>
                </div>
            </el-tabs>
        </div>
    </div>
</template>

<script>
import Host from "../store/host"
export default {
    data() {
      return {
        ckeditorLoading: null,
        textLoading: null,
        label: 'yh',
        content: '',
          salesmanData: {
            weChat: "",
              phone: ""
          }

      }
    },
    mounted(){

    },
    methods: {
        saveContent(){
            // 获取内容
            this.$refs.iframe.contentWindow.content();
            window.addEventListener('message',this.getiframeMsg)
        },
        // 接收iframe
        getiframeMsg(event){
            const res = event.data;
            if(res.cmd == 'HtmlText'){
                this.content = res.params;
                this.setAgreement();
            }
        },
        // 发送iframe
        saveIframeMsg(msg){
            const iframeWindow = this.$refs.iframe.contentWindow;
            iframeWindow.postMessage({cmd:'myVue',params : msg },'*')
            this.$refs.iframe.contentWindow.VueMsg();
        },
        // 监听Iframe加载
        onLoadIframe(){
            console.log('加载完成');
             this.saveIframeMsg();
             this.ckeditorLoading = false;
              this.getAgreement()
        },
        // 标签点击
        async btnPane(e){
            this.label = e;
            if(e == 'salesman'){
                // 请求数据
                let getUrl = '/api/getSalesman'
                let resolveData = await Host.HostGet(getUrl);
                console.log(resolveData);
                this.salesmanData.weChat = resolveData.data.salesmanWechat;
                this.salesmanData.phone = resolveData.data.salesmanPhone;
                this.ckeditorLoading = true;
                this.textLoading = false;
            } else {
                this.getAgreement();
                this.ckeditorLoading = false;
            }

        },
        // 获取协议
        async getAgreement(){
            this.textLoading = true;
            let getUrl = '/api/agreement?label=' + this.label;
            let resolveData = await Host.HostGet(getUrl);
            this.saveIframeMsg(resolveData.content)
            this.textLoading = false;

        },
        // 更新业务员联系方式
        async onSubmit(){
            let postUrl = '/api/setSalesman';
            let data = {weChat: this.salesmanData.weChat, phone:this.salesmanData.phone};
            if(data.weChat.length == 0 || data.phone.length == 0) return this.$message({message: '内容不能为空',type: 'error'});
            let resolveData = await Host.HostPost(postUrl,data);
            if(resolveData.code != 200) return this.$message({message: '更新失败',type: 'error'});
            this.$message({message: '更新成功',type: 'success'});
            return;
        },
        // 更新协议
        async setAgreement(){
            let postUrl = '/api/agreement';
            let data = {label: this.label, content:this.content};
            if(this.content.length == 0) return this.$message({message: '内容不能为空',type: 'error'});
            let resolveData = await Host.HostPost(postUrl,data);
            if(resolveData.code != 200) return this.$message({message: '更新失败',type: 'error'});
            this.$message({message: '更新成功',type: 'success'});
            return;
        }
    }
}
</script>


<style>
    
    .box {
        display: flex;
        /* flex-wrap: wrap; */
    }
    .ckeditorBox {
        height: calc(100vh - 190px);
    }

    /* 编辑器下方按钮样式 */
    .buttomBox {
      margin-top: 10px;
      width: 100%;
    }

    .buttomBox button{
        float: right;
        margin-right: 20px;
    }
    .iframeBox {
        width: 100%; height: 75vh;
    }





  /* 编辑器滚动条 */
    .ck-content {
        width: 100%;
        height: calc(100vh - 190px);
        overflow-y: scroll;
    }

    /* 滚动条整体样式 */
    .ck-content::-webkit-scrollbar {
        width: 5px;
        background-color: #f5f5f5;
    }

    /* 滚动条轨道样式 */
    .ck-content::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        border-radius: 10px;
        background-color: #f5f5f5;
    }

    /* 滚动条滑块样式 */
    .ck-content::-webkit-scrollbar-thumb {
        border-radius: 10px;
        box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background-color: #ccc;
    }

    /* 鼠标放在滚动条上的样式 */
    .ck-content::-webkit-scrollbar-thumb:hover {
        background-color: #bbb;
    }
</style>
