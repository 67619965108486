import { createRouter, createWebHistory } from 'vue-router'

import HomeView from '../components/HomeView.vue'

import Agreement from '../views/AgreementView.vue'
import dataShow from '../views/DataShow.vue'
import NavView from '../views/NavView.vue'
import SetPrice from '../views/SetPrice.vue'
import ZdAdmin from '../views/ZdAdmin.vue'
import ComDepartmemt from '../views/ComDepartmemt.vue'
import UserView from '../views/UserView.vue'
import McView from '../views/McView.vue'
import OrderView from '../views/OrderView.vue'
import RecycleView from '../views/RecycleView.vue'
import RecyclingHouse from '../views/WareHouse/RecyclingView.vue'
import WechatMP from '../views/WechatMP/WechatMpView.vue'

import LoginView from '../components/LoginView.vue'
import ActionMain from '../components/ActionMain/Index.vue'
import viewError from '../components/viewError.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView
  },
  {
    path: '/error',
    name: 'Error',
    component: viewError
  },
  {
    path: '/admin', // 后台路径
    name: 'admin',
    component: ActionMain,
    meta: {
      title: '万象后台',
      isLogin: true
    },
    children: [
      {
        path: '/dataShow',
        name: '数据概述',
        component: dataShow
      },
      {
        path: '/Agreement',
        name: '文档协议',
        component: Agreement
      },
      {
        path: '/admins',
        name: '站点管理员',
        component: ZdAdmin
      },
      {
        path: '/ComDepartmemt',
        name: '公司部门',
        component: ComDepartmemt
      },
      {
        path: '/mc',
        name: '经营体管理',
        component: McView
      },
      {
        path: '/wechatmp',
        name: '小程序配置',
        component: WechatMP
      },
      {
        path: '/order',
        name: '零售订单',
        component: OrderView
      },
      {
        path: '/recycle',
        name: '回采订单',
        component: RecycleView
      },
      {
        path: '/users',
        name: '用户管理',
        component: UserView,
      },
      {
        path: '/navConfig',
        name: '产品目录',
        component: NavView
      },
      {
        path: '/setPrice',
        name: '产品报价',
        component: SetPrice
      },
      {
        path: '/recyclingHouse',
        name: '回收仓',
        component: RecyclingHouse
      },
      
      
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


const defaultTitle = '万象后台'; 
// 路由拦截
router.beforeEach((to, from, next) => {

  // 标题
  document.title = to.meta.title ? to.meta.title : defaultTitle;

  

  // 检查路由是否存在
  const isRouteExist = routes.some(route => route.path === to.path || (route.children && route.children.some(childRoute => childRoute.path === to.path)));

  // 路由存在，继续导航
  if (!isRouteExist) {
    next('/error');
    return;
  }

  if(to.path === "/login"){
    if(!localStorage.getItem('token')){next();return;} // 登录失效
    else {
      return next('/dataShow');
    }
  } else if(!localStorage.getItem('token')){next('/login');return;} // 登录失效

  // 权限路由
  if(localStorage.getItem('grade') == 1){next();return;} // 超级管理

  const department = localStorage.getItem('department');
  if(department.includes('运营')){
    if(localStorage.getItem('grade') == 2){ // 管理
      switch(to.path){
        case '/dataShow':
        case '/ComDepartmemt':
        case '/mc':
        case '/order':
        case '/users':
        case '/navConfig':
        case '/setPrice':
          return next();
        default : next('/dataShow');
      }
    } else { // 成员
      switch(to.path){
        case '/dataShow':
        case '/mc':
        case '/order':
        case '/users':
        case '/navConfig':
        case '/setPrice':
          return next();
        default : next('/dataShow');
      }
    }
  }

  if(department.includes('财务')){
    if(localStorage.getItem('grade') == 2){ // 管理
      switch(to.path){
        case '/dataShow':
        case '/ComDepartmemt':
        case '/order':
          return next();
        default : next('/dataShow');
      }
    } else { // 成员
      switch(to.path){
        case '/dataShow':
        case '/order':
          return next();
        default : next('/dataShow');
      }
    }
  }



  // if (to.path === '/login') { // 登录

  //   if (!localStorage.getItem('token')) {
  //     next();
  //   } else if(localStorage.getItem('grade') == 1){ 
  //     next('/dataShow')
  //   } else {next('/mc')}


  // } else {

  //   if (!localStorage.getItem('token')) {
  //     next('/login');
  //   } else {
  //     next();
  //   }

  // }

  
});
export default router
