export default {
    copyText,
}
function copyText(text){
    // 复制文字
    const el = document.createElement('textarea'); // 创建 textarea 元素
    el.value = text; // 设置文本框的值
    document.body.appendChild(el); // 添加到页面中
    el.select(); // 选中文本
    document.execCommand('copy'); // 执行复制命令
    document.body.removeChild(el); // 移除文本框
}