<!--

        站点管理

-->
<template>
    <div class="box">
        <el-tabs type="border-card" style="width: 100%;"  @tab-change="btnPane" v-model="label">
            <el-tab-pane label="部门管理" name="2"></el-tab-pane>
            <el-tab-pane label="成员管理" name="2"></el-tab-pane>
            <div style="height: 10px;"></div>

            <div style="width: 100%; display: flex;">
                <el-button type="primary" size="small" @click="getLoadShow">添加管理员</el-button>
                <el-button type="success" size="small" @click="McShateQRCodedialog = true">查看企业注册码</el-button>
            </div>
            <div style="height: 30px;"></div>
            <!-- 站点管理员盒子 -->
            <div>
                 <el-table height="550" :data="tableData" v-loading="tableLoading">
                    <el-table-column label="头像" width="100" align="center">
                        <template #default="scope">
                            <el-avatar shape="square" :size="50" :src="scope.row.headImg" />
                        </template>
                    </el-table-column>

                    <el-table-column label="微信昵称" width="120" align="center">
                        <template #default="scope">
                            <el-text> {{ scope.row.name }} </el-text>
                        </template>
                    </el-table-column>


                    <el-table-column label="添加时间" width="180" align="center">
                        <template #default="scope">
                            <el-text> {{ scope.row.regTime }} </el-text>
                        </template>
                    </el-table-column>

                    <el-table-column label="超级管理" width="120" align="center">
                        <template #default="scope">
                            <el-icon v-if="scope.row.cj"><Check /></el-icon>
                            <el-icon v-else><Close /></el-icon>
                        </template>
                    </el-table-column>



                     <el-table-column></el-table-column>

                    <el-table-column fixed="right" label="操作" width="200">
                        <template #default="scope">
                            <el-button @click="delAdmin(scope.row.openId)" link type="danger" size="small">删除</el-button>
                        </template>
                    </el-table-column>

                </el-table>
            </div>
        </el-tabs>
        <!-- 添加站点管理员弹窗 -->
        <el-dialog v-model="adddialog" align-center title="授权管理员" width="30%" @close="adddialogClose">             
            <div class="loginImgBox" v-show="qrShow">
                <div v-show="loadShow"><el-image style="width: 300px; height: 300px;border-radius: 10px;" :src="qr_code" /></div>
                <div v-show="!loadShow"><el-image style="width: 50px; height: 50px" :src="loadImg" /></div>
                
            </div>
            <div v-show="qrShow">请在 {{ count }} 秒内完成扫码</div>
        </el-dialog>
        <!--  查看企业邀请码弹窗 -->
        <el-dialog v-model="McShateQRCodedialog" align-center title="企业邀请码" width="30%">             
            <div class="loginImgBox" style="margin-top: 0;">
                <div><el-image style="width: 300px; height: 300px;border-radius: 10px;" :src="McShateQRCode" /></div>
            </div>
            <el-button @click="downloadImage" type="success" size="small">保存二维码</el-button>
        </el-dialog>
    </div>
</template>

<script>
import Host from "../store/host";
import qrcode from 'qrcode';
export default {
    data(){
        return {
            tableData: [],
            tableLoading: false,
            adddialog: false,
            qrShow: false,
            beName: "",
            loadImg: require("@/assets/loadImg.gif"),
            qr_code: '',
            count: 0,
            timer: null,
            uid: this.getRandomInt(1, 10000),
            McShateQRCodedialog:false,
            McShateQRCode: require("@/assets/McShateQRCode.png"),
        }
       
    },
    methods: {
        // 打开添加管理员窗口
        getqrShow(){
            
        },
        // 请求链接
        async getLoadShow(){
            let postUrl = `/api/wechat/qrCode?type=auth&uid=${this.uid}`;
            let resolveData = await Host.HostGet(postUrl);
            if(resolveData.code != 200) return this.$message({message: resolveData.msg,type: 'warning'});
            await this.createQrCode(resolveData.url);
            this.adddialog = true;
            this.qrShow = true;
            this.count = 60;
            this.countDown(); // 倒计时
        },
        // qrCode生成二维码
        async createQrCode(url){
            qrcode.toDataURL(url, { width: 200 }).then(url => {
                this.qr_code = url;
                this.loadShow = true;
                return true;
            }).catch(err => {return false;});
        },
        // 获取列表
        async getList(){
            this.tableLoading = true;
            let postUrl = '/api/getAdminList';
            let resolveData = await Host.HostGet(postUrl);
            this.tableData = await Host.setList(resolveData.data);
            console.log(this.tableData)
            this.tableLoading = false;
        },
         // uid
        getRandomInt(min, max) {
            return Math.floor(Math.random() * (max - min + 1)) + min;
        },
        // 监听登录
        async getlogin(){
            let postUrl = `/api/wechat/get?uid=${this.uid}`;
            let resolveData = await Host.HostGet(postUrl);
            console.log(resolveData);
            if(resolveData.code != 200) return;
            // 关闭倒计时
            clearTimeout(this.timer);
            this.$message({message: '授权成功',type: 'success'});
            this.qrShow = false;
            this.adddialog = false;
            this.getList();
        },
        // 添加管理员窗口关闭
        adddialogClose(){
            clearTimeout(this.timer);
            this.adddialog = false;
            this.qrShow = false;
        },
        // 倒计时
        countDown() {
            this.timer = setTimeout(() => {
                this.count--;
                if (this.count > 0) {
                    // 监听
                    this.getlogin();
                    this.countDown();
                } 
                else {
                    this.$message({message: '已超时!',type: 'warning'});
                    this.adddialog = false;
                    this.loadShow = false;
                    this.qrShow = false;
                }
            }, 1000); // 每1秒执行一次
        },
        // 删除管理员
        async delAdmin(openId){
            this.$confirm('确定要删除吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                let tc = this.$message({
                    message: '加载中',
                    type: 'info',
                    duration: 0
                });
                    // 提交
                let postUrl = '/api/delAdmin';
                let data = {openId: openId};
                let resolveData = await Host.HostPost(postUrl,data);
                tc.close();
                if(resolveData.code !== 200) return this.$message({message: '删除失败',type: 'danger'});
                this.$message({message: '删除成功',type: 'success'});
                this.getList();
            }).catch(() => {});
        },
        // 下载企业邀请码
        downloadImage() {
            const imageSrc = require("@/assets/McShateQRCode.png");
            console.log(imageSrc)
            const link = document.createElement('a');
            link.href = imageSrc;
            link.download = 'McShateQRCode.png';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    },
    created(){
        this.getList()
    }
} 
</script>

<style>
    .spanT {
        /* margin: 30px 0; */
        color: #000;
    }
</style>