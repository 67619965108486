<template>
    <!-- 分页 -->
        <el-pagination background layout="prev, pager, next" :total="total"  @current-change="changePage" :page-size="pageSize"/>
</template>

<script>
    export default {
        props: {
            total: {
                type: Number,
                default: 10,
            },
            pageSize:{
                type: Number,
                default: 100,
            },
        },
        data(){
            return{

            }
        },
        methods:{
             changePage(current){
                this.$emit('changePage',current)
            }
        },
        created(){
            // console.log(maxNum)
        }
        
    }
</script>
    
<style>

</style>