<template>
    <div class="app">
        <center><h1>404 Not Found</h1></center>
        <hr><center>找不到指定页面</center>
    </div>
</template>
  
<script>
    export default {

    }
</script>

<style>
    .app {
        padding-top: 10px;
        height: 100vh;
        background: #fff;
    }
</style>