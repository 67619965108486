<!--

        报价信息 

-->
<template>
    <div class="box">
        <div class="leftTreeBox">
            <div class="radio" style="display: flex; align-items: center;">
                <el-select style="width: 180px;" v-model="wechat" class="m-2" placeholder="Select" @change="handWechat">
                    <el-option label="零售" value="零售" />
                    <el-option label="回收" value="回收"/>
                </el-select>
                <el-select style="width: 180px;" v-model="radio" class="m-2" placeholder="Select" @change="handRadio">
                    <el-option label="全新" value="全新" />
                    <el-option label="二手" value="二手" v-if="wechat == '零售'"/>
                </el-select>
                <div>
                    <!-- excel更新 -->
                    <el-upload
                        style="display: flex; margin-left: 10px;"
                        :action="uploadExcel"
                        :on-success="onExcelSuccess"
                        :before-upload="onExcelUpload"
                        :limit="3"
                        :show-file-list="false"
                    >
                        <el-button style="font-size: 10px;" type="primary">上传报价</el-button>
                    </el-upload>
                </div>
                <el-input style="margin-left: 10px;" placeholder="输入关键字进行过滤" v-model="filterText" clearable></el-input>
            </div>
           
            <div style="height: 10px;"></div>
            <div style="overflow-y:scroll;overflow-x:hidden;height: calc(100% - 150px)">
                <el-tree :data="navData" v-loading="treeLoading" :props="defaultProps" ref="tree"  @node-click="handleNodeClick" :filter-node-method="filterNode"></el-tree>
            </div>
        </div>
        <div class="seg"></div>
        <div class="rightBox">
            <el-empty v-show="!priceBox" description="请选择"></el-empty>
            <!-- 价格数据 -->
            <div class="priceBox" v-show="priceBox">
                <el-card class="box-card" style="width: 100%; height: calc(100% - 50px);">
                    <div class="clearfix">
                        <span style="float: left; font-weight: 600; color: #F56C6C; text-overflow: ellipsis; white-space: normal;word-break: break-all;">● {{ priceTitle }}</span>
                        <el-button style="float: right; margin-right: 20px; padding: 0px 20px"  @click="addCategory" type="primary" plain>
                        <el-icon><Plus/></el-icon>
                        </el-button>
                    </div>
                    <div style="height: 50px;"></div>
                    <!-- 表格 -->
                    <div class="Infos">
                       <el-table :data="tableData" v-loading="loading" style="width: 110%" height="550">
                            <!-- <el-table-column type="selection" width="55" /> -->
                            <el-table-column label="描述">
                                <template #default="scope">
                                    <el-input placeholder="请输入描述" v-model="scope.row.color"></el-input>
                                </template>
                            </el-table-column>

                            <el-table-column label="零售价" width="100">
                            <template #default="scope">
                               <el-input placeholder="请输入价格" v-model="scope.row.price"></el-input>
                            </template>
                            </el-table-column>
                             <el-table-column label="调货价" width="100">
                            <template #default="scope">
                               <el-input placeholder="请输入价格" v-model="scope.row.vipPrice"></el-input>
                            </template>
                            </el-table-column>
                            <el-table-column fixed="right" label="操作" width="250">
                            <template #default="scope">
                                <el-button link type="success" size="small" @click="SeriaBtn(scope.row.id,scope.row.color,scope.row.serialNumber)">编号</el-button>
                                <el-button link type="success" v-if="radio == '二手'" size="small" @click="getBarCode(scope.row.serialNumber,scope.row.color)">条形码</el-button>
                                <el-button link type="success" v-if="radio == '二手'" size="small" @click="ershoDrawerBtn(scope.row.id,scope.row.color)">图片</el-button>
                                <el-button link type="danger" size="small" @click="delPrice(scope.row.id)">删除</el-button>
                                <el-button link type="primary" size="small" @click="updatePrice(scope.row.id,scope.row.color,scope.row.price,scope.row.vipPrice)">更新</el-button>
                            </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </el-card>
            </div>
        </div>
         <!-- 添加 -->
            <el-dialog v-model="dialogAddBox" align-center title="添加报价信息" width="20%"> 
                <el-input v-model="priceTitle" disabled>
                    <template #prepend>所属类目</template>
                </el-input>
                <div style="height: 20px;"></div>

                 <el-form :model="form" ref="form" :rules="rules">
                    <el-form-item prop="color">
                        <el-input v-model="form.color" placeholder="请输入描述"  autocomplete="off" clearable></el-input>
                    </el-form-item>
                    <el-form-item prop="price">
                        <el-input v-model="form.price" placeholder="零售价"  autocomplete="off" clearable></el-input>
                    </el-form-item>
                    <el-form-item prop="vipPrice">
                        <el-input v-model="form.vipPrice" placeholder="调货价"  autocomplete="off" clearable></el-input>
                    </el-form-item>
                 </el-form>

                <template #footer>
                    <span class="dialog-footer">
                        <el-button type="primary" @click="addBtn">确认</el-button>
                    </span>
                </template>
            </el-dialog>
            <!-- 设置编号 -->
            <el-dialog v-model="dialogSerialBox" align-center title="配置编号" width="20%"> 
                <el-input v-model="serialBoxTitle" disabled>
                    <template #prepend>目录</template>
                </el-input>
                <div style="height: 20px;"></div>

                <el-input v-model="serialNumber" placeholder="请输入编号"  autocomplete="off" clearable></el-input>

                <template #footer>
                    <span class="dialog-footer">
                        <el-button type="primary" @click="serialNumberBtn">确认</el-button>
                    </span>
                </template>
            </el-dialog>
             <!-- 二手图片抽屉 -->
            <el-drawer v-model="ershoDrawer" :title="ershoTitle" direction="ltr" size="30%">
                <div style="height:80px; display: flex; align-items: center; margin: 10px 0;">
                    <el-upload style="width: 100%;"
                        drag
                        :before-upload="beforeUpload"
                        :on-success="onSuccess"
                        :headers="Headers"
                        :multiple="true"
                        :action= "action"
                        :show-file-list="false"
                    >
                        <el-icon class="el-icon-upload"><i class="fas fa-upload"></i></el-icon>
                        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em> jpg/png</div>
                    </el-upload>
                </div>
                <div style="height: 20px;"></div>
                <!-- 图集展示 -->
          
                    <div class="img-container" v-loading="ImgListLoad">
                        <div>
                            <template v-for="(item,index) in srcList" :key="index">
                                <el-image
                                    style="width: 100px; height: 100px"
                                    :src="item.url"
                                    :zoom-rate="1.2"
                                    :preview-src-list="srcLists"
                                    :initial-index="index"
                                    fit="cover"
                                    @contextmenu.prevent="delFileImg(item.id,item.fileName)"
                                />
                            </template>
                        </div>
                </div>
            </el-drawer>
            <!-- 条形码 -->
            <el-dialog v-model="barCodeShow" :title="priceTitle + '-' + barCodeTitle">
                <img :src="barcodeDataURL" alt="">
                <template #footer>
                <span class="dialog-footer">
                    <el-button type="primary" @click="copyText(qrCodeTitleInfo + ' ' + barCodeTitle,'已复制')">复制信息</el-button>

                    <el-button type="success" @click="downBarcode">保存条形码</el-button>
                </span>
                </template>
            </el-dialog>

            <!--  弹窗table 显示上传错误数据-->
            <el-dialog v-model="excelTableVisible" title="修改失败信息" width="800">
                <el-table :data="excelUpdateErrorData">
                    <el-table-column property="id" label="编号" width="400" />
                    <el-table-column property="errMsg" label="状态" width="400" />
                </el-table>
            </el-dialog>
    </div>
</template>

<script>
import Host from "../store/host";
import Method from "../store/publicMethod";
import JsBarcode from 'jsbarcode';
export default {
    data(){
        return {
            filterText: '',
            navData: [],
            tableData: [],
            defaultProps: {
            children: 'children',
            label: 'label'
            },
            wechat: "零售",
            radio: '全新', // 单选框
            priceBox: false, // 显示
            priceTitle: "",
            att: "全新",
            cid: null,
            loading: true,
            treeLoading: true,
            dialogAddBox: false, // 添加对话框
            serialId: null,
            serialNumber: null,
            serialBoxTitle: '',
            dialogSerialBox: false, // 配置编号对话框
            form: {
                color: '',
                price: null,
                vipPrice: null,
            },
            rules: {
                color:[
                    { required: true, message: '描述不能为空', trigger: 'blur' },
                    { min: 1, max: 10, message: '类目名长度在 1 到 10 个字符', trigger: 'blur' }
                ],   
                price:[
                    { required: true, message: '普通价不能为空', trigger: 'blur' },
                    { min: 1, max: 10, message: '类目名长度在 1 到 10 个字符', trigger: 'blur' }
                ],     
                vipPrice:[
                    { required: true, message: '会员价不能为空', trigger: 'blur' },
                    { min: 1, max: 10, message: '类目名长度在 1 到 10 个字符', trigger: 'blur' }
                ]     
            },
            SelectionList: [],
            ershoDrawer: false, // 二手图集抽屉
            ershoTitle: '',
            srcList: [], // 二手图集列表
            srcLists: [], // 二手图片列表
            imgCid: null,
            action: "/api/uploadimg?cid=",
            uploadExcel: '/javaApi/excel/price',
            retailUploadUrl:"/javaApi/excel/price",
            recyclelUploadUrl:"/javaRecycleApi/excel/price",
            Headers: {code:localStorage.getItem('token')}, // 上传请求头
            FromImgList:[],
            ImgListLoad: null,
            barCodeShow: false, // 条形码框显示
            barCodeTitle: '',
            barcodeDataURL: "",
            qrCodeTitleInfo: "",
            excelUpdateErrorData: [],
            excelTableVisible: false
        }
    },
    watch: {
      filterText(val) {
        this.$refs.tree.filter(val);
      }
    },
    mounted() {
         
    },
    methods: {
        filterNode(value, data) {
            if (!value) return true;
            return data.label.indexOf(value) !== -1;
        },
        // 树形导航
        handleNodeClick(e){
            if(!e.att || !e.id) return;
            this.priceTitle = e.label;
            this.att = e.att;
            this.cid = e.id;
            this.priceBox = true;
            this.qrCodeTitleInfo = '';
            if(e.att == "config"){
                const result = this.findById(this.navData, e.id);
                if (result) {
                    const labels = [result.item.label, ...result.path];
                    this.qrCodeTitleInfo = labels.reverse().join(' ');
                }
            }
            // 获取报价信息
            this.getPrive(e.att,e.id)
        },
        handWechat(val){
            if(val == '回收'){
                this.priceTitle = "全新";
                this.att = "全新";
                this.radio = "全新";
                this.uploadExcel = this.recyclelUploadUrl;
            } else {
                this.priceTitle = "全新";
                this.att = "全新";
                this.uploadExcel = this.retailUploadUrl;

            }
            this.treeLoading = true;
            this.getNav()
        },
         // 单选框
        handRadio(val){
            this.priceTitle = val;
            this.att = val;
            this.priceBox = false;
            this.getNav()
        },
        // 添加
        addCategory(){
            this.dialogAddBox = true;
        },
        // 添加确认
        addBtn(){
            this.$refs.form.validate(async valid => {
                if(!valid) return false;
                // 添加
                this.dialogAddBox = false;

                let postUrl;
                if(this.wechat == "零售"){
                    postUrl = '/api/addNav';
                } else {postUrl = '/recycleApi/addNav';}
               
                if(this.att !== "config") return this.$message({message: '数据有误',type: 'danger'});
                let data = {
                    type:4, 
                    color: this.form.color,
                    weight: this.tableData.length,
                    cid:this.cid,
                    price: this.form.price,
                    vipPrice: this.form.vipPrice};
                let resolveData = await Host.HostPost(postUrl,data);
                if(resolveData.code !== 200) return this.$message({message: '添加失败',type: 'danger'});
                 this.form.price = "";
                 this.form.vipPrice = "";
                 this.form.color = "";
                // 二次刷新
                this.getPrive(this.att,this.cid);
                this.$message({message: '添加成功',type: 'success'});
            });
        },
        // 获取列表
        async getNav(){
            Host.load = true;
            this.loading = true;
            this.treeLoading = true;
            let postUrl;
            if(this.wechat == "零售"){
                postUrl = '/api/getNav?att=' + this.att + '&any=true';
            } else {postUrl = '/recycleApi/getNav?att=' + this.att + '&any=true';}
        
            let resolveData = await Host.HostGet(postUrl);
            if(resolveData.code !== 200){
                this.loading = false;
                this.$message({message: '获取列表信息错误',type: 'warning'});
                return;
            }
            this.navData = resolveData.data;
            this.loading = Host.load;
            this.treeLoading = Host.load;
        },
        // 获取报价信息
        async getPrive(att,cid){
            Host.load = true;
            this.loading = true;
            let postUrl;
            if(this.wechat == "零售"){
                postUrl = '/api/priveList?att=' + att + '&cid=' + cid;
            } else {postUrl = '/recycleApi/priveList?att=' + att + '&cid=' + cid;}

            let resolveData = await Host.HostGet(postUrl);
            if(resolveData.code !== 200){
                this.loading = false;
                this.$message({message: '获取报价信息错误 ',type: 'warning'});
                return;
            }
            this.priceBox = true;
            this.tableData = resolveData.data;
            this.loading = Host.load;
        },
        // 删除报价信息
        async delPrice(id){
            let att = null;
            if(this.att == "config"){
                att = "color";
            }
            this.$confirm('此操作将永久删除关联类目的数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                let tc = this.$message({
                    message: '加载中',
                    type: 'info',
                    duration: 0
                });
                if(att == null) return;
                // 提交
                let postUrl;
                if(this.wechat == "零售"){
                    postUrl = '/api/setPrice/del';
                } else {postUrl = '/recycleApi/setPrice/del';}
                let data = {att: att,id: id};
                let resolveData = await Host.HostPost(postUrl,data);
                tc.close();
                if(resolveData.code !== 200) return this.$message({message: '添加失败',type: 'danger'});
                this.getPrive(this.att,this.cid);
                this.$message({message: '删除成功',type: 'success'});
            }).catch(() => {});
        },
        // 更新报价信息
        async updatePrice(id,color,price,vipPrice){
            let tc = this.$message({
                message: '加载中',
                type: 'info',
                duration: 0
            });
            let att = null;
            if(this.att == "config"){
                att = "colorlevel";
            }
            if(att == null) return;
            // 提交
            let postUrl;
            if(this.wechat == "零售"){
                postUrl = '/api/setPrice/update';
            } else {postUrl = '/recycleApi/setPrice/update';}
            let data = {att: att,id: id,color:color,price:price,vipPrice: vipPrice,type: "color"};
            let resolveData = await Host.HostPost(postUrl,data);
            tc.close();
            if(resolveData.code !== 200) return this.$message({message: '失败',type: 'danger'});
              this.getPrive(this.att,this.cid);
            this.$message({message: '修改成功',type: 'success'});
        },
        // 二手图集
            //抽屉
            ershoDrawerBtn(id,name){
                this.imgCid = id;
                this.action = `/api/uploadimg?cid=${id}`;
                // 获取图片列表
                this.srcList = [];
                this.fileImgList();
                this.ershoTitle = name;
                this.ershoDrawer = true;
            },
             // 上传组件事件
            beforeUpload(file) {
                return true; // 返回 true 表示允许上传，返回 false 表示取消上传
            },
            onSuccess(response) {
                // 上传成功后，可以在此处理服务器返回的数据
                if(response.code !== 200) return this.$message({message: response.msg, type: 'warning'});
                this.$message({message: response.msg, type: 'success'});
                this.fileImgList();
            },
           // 获取图集
           async fileImgList(){
                this.ImgListLoad = true;
                let postUrl = '/api/fileImgList?cid=' + this.imgCid;
                let resolveData = await Host.HostGet(postUrl);
                this.ImgListLoad = false;
                if(resolveData.code !== 200) return this.$message({message: resolveData.msg, type: 'warning'});
                const data = []
                const datas = []
                let obj;
                // 遍历列表
                resolveData.data.forEach(element => {
                    obj = new Object();
                    obj.id  = element.id;
                    obj.fileName  = element.fileName;
                    obj.url = "http://wximg.slenb.cn/ersho/" + element.fileName;
                    data.push(obj);
                    datas.push(obj.url);
                })
                this.srcList = data;
                this.srcLists = datas;
           },
           // 右键删除图片
           delFileImg(id,fileName){
                this.$alert('', '是否要删除该图片', {
                        confirmButtonText: '确定',
                         callback: async action => {
                            if (action === 'confirm') {
                                let tc = this.$message({
                                    message: '加载中',
                                    type: 'info',
                                    duration: 0
                                });
                                let postUrl = '/api/delFileImg';
                                let data = {id: id,fileName: fileName};
                                let resolveData = await Host.HostPost(postUrl,data);
                                tc.close();
                                if(resolveData.code !== 200) return this.$message({message: resolveData.msg,type: 'danger'});
                                this.$message({message: '删除成功',type: 'success'});
                                this.fileImgList();
                            }
                        }
                });
           },
           // 配置编号对话框
           SeriaBtn(id,color,serialNumber){
                this.serialId = id;
                this.serialBoxTitle = color;
                this.serialNumber = serialNumber;
                this.dialogSerialBox = true;
           },
           // 更新产品编号
           async serialNumberBtn(){
                 let tc = this.$message({
                    message: '加载中',
                    type: 'info',
                    duration: 0
                });
                let postUrl;
                if(this.wechat == "零售"){
                    postUrl = '/api/setSerialNumber';
                } else {postUrl = '/recycleApi/setSerialNumber';}
                let data = {id: this.serialId,serialNumber: this.serialNumber};
                let resolveData = await Host.HostPost(postUrl,data);
                tc.close();
                if(resolveData.code !== 200) return this.$message({message: resolveData.msg,type: 'danger'});
                this.getPrive(this.att,this.cid);
                this.dialogSerialBox = false;
                this.$message({message: '配置成功',type: 'success'});
           },
           // 生成条形码
           async getBarCode(serialNumber, title){
            this.barCodeTitle = title;
            console.log(title)
            this.barCodeShow = true;   
            await this.$nextTick(); // 等待DOM更新
            // const canvas = this.$refs.canvas;
             
            // 绘制文字
            const canvas = document.createElement('canvas');
            canvas.width = 600; // 替换为想要的自定义宽度
            canvas.height = 350; // 替换为想要的自定义高度
            
            const context = canvas.getContext('2d');
            context.fillStyle = "#fff"; // 背景
            context.fillRect(0, 0, canvas.width, canvas.height);
            context.font = 'bold 25px Arial';
            context.fillStyle = '#000';
            context.textAlign = 'center';

            // 绘制条形码
            const barcode = document.createElement('canvas');
            JsBarcode(barcode, serialNumber, {
                format: 'CODE128',
                width: 2,
                height: 220,
                displayValue: false
            });
            context.fillText(serialNumber,canvas.width / 2,35); // 产品编号
            context.drawImage(barcode, canvas.width / 2 - 250, 50,500,220); // 条形码
            context.font = 'bold 22px Arial';
            context.fillText(this.qrCodeTitleInfo,canvas.width / 2,canvas.height - 55);
            context.fillText(title,canvas.width / 2,canvas.height - 25);
            this.serialNumber = serialNumber;
            this.barcodeDataURL = canvas.toDataURL('image/png');
            context.closePath();
            context.stroke();
            },
            // 保存条形码
            downBarcode(){
                const link = document.createElement('a');
                link.href = this.barcodeDataURL;
                link.download = this.serialNumber + '.jpg';
                link.click();
            },
            findById(data, targetId, path = []) {
                let result = null;
                for (let item of data) {
                    if (item.id === targetId) {result = {item: item,path: path};break;}
                    if (item.children && item.children.length > 0) {
                        const newPath = [item.label, ...path];
                        const childResult = this.findById(item.children, targetId, newPath);
                        if (childResult) {result = childResult;break;}
                    }
                }
                return result;
            },
            // 复制文字
            copyText(text,msg){
                Method.copyText(text,msg);
                this.$message({message: '已复制' + msg,type: 'success'});
            },

            // excel上传事件
            onExcelSuccess(e){
                this.treeLoading = false;
                console.log(e);
                if(e.code == 200){
                    // 上传成功
                    if(e.data.length !== 0){
                        this.excelUpdateErrorData = e.data;
                        this.$alert(e.data.length + '个修改失败', '上传成功', {
                            confirmButtonText: '查看失败信息',
                            callback: action => {
                                this.excelTableVisible = true;
                            }
                        });
                    } else {
                        this.$alert('无修改失败', '上传成功', {
                            confirmButtonText: '确定',
                        });
                    }

                } else {
                    this.$message.error(e.msg)
                }
                // if(e.includes("修改成功")){
                //     this.$alert(e, '上传结果', {
                //         confirmButtonText: '确定',
                //         callback: action => {}
                //     });
                //     return;
                // }
                // if(e.includes("格式错误")){
                //     this.$message.error(e);
                //     return;
                // }
                // this.$message({
                //     message: e,
                //     type: 'warning'
                // });
            },
            // 上传交互
            onExcelUpload(){
                this.treeLoading = true;
            }
            // end
    },
    created(){
        this.getNav()
    }
}
</script>

<style>
    .box {
        display: flex;
        justify-items: center;
    }
    .leftTreeBox {
        width: 25vw;
        margin: 10px;
    }
    .seg {
        width: 2px;
        background: #F2F6FC;
    }
    .rightBox {
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .priceBox {
        /* width: 50vw; */
        margin: 20px;
        display: flex;
        /* justify-self: unset; */
    }
    .box-card {
        box-shadow: none !important;
        border: none !important;
    }
    /* 排序图片按钮 */
    .weightHove {
        border-radius: 100px;
        margin: 5px;
    }
    .weightHove:hover {
        cursor: pointer;
        color: #F56C6C;
    }
    /* 二手产品图集 */
    .img-container {
        display: flex;
        flex-wrap: wrap;
        margin: 0 auto;
        width: 100%;
        height: calc(100vh - 300px); 
        overflow-y: auto; 
        overflow-x: hidden;
    }

    .img-container .img {
        width: calc(100% / 3 - 20px);
        height: 100px;
        margin: 10px;
        box-sizing: border-box;
    }
</style>