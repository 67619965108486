<!--

        用户管理 

-->
<template>
    <div class="box">
        <el-tabs type="border-card" style="width: 100%;" @tab-change="btnPane" v-model="label">
            <el-tab-pane label="正常用户" name="2"></el-tab-pane>
            <el-tab-pane label="已封禁" name="0"></el-tab-pane>
            <!-- 用户搜索 -->
            <div class="searchBox">
                <el-input style="width: 300px;" v-model="input" placeholder="可通过用户ID或手机号搜索" clearable/>
                <el-button style="margin-left: 10px;" type="primary" @click="searchBtn" circle><el-icon><Search /></el-icon></el-button>
            </div>

            <div style="height: 20px;"></div>
            <!-- 用户数据 -->
            <div style="display: flex;">
                <el-table height="530" :data="tableData" style="width: 100%;" v-loading="tableLoading">
                    <el-table-column label="用户ID" width="120">
                        <template #default="scope">
                            <el-tag style="display: flex;">{{ scope.row.id }}</el-tag>
                        </template>
                    </el-table-column>

                    <el-table-column label="上级ID" width="120">
                        <template #default="scope">
                            <el-tag style="display: flex;">{{ scope.row.mid == -1 ? '无':scope.row.mid }}</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column prop="phone" label="手机号" width="160"/>

                    <el-table-column prop="regTime" label="注册时间" width="160"/>

                    <el-table-column label="状态">
                        <template #default="scope">
                            <el-tag type="info" v-if="(scope.row.state == 1)">待完善</el-tag>
                            <el-tag type="success" v-else-if="(scope.row.state == 2 || scope.row.state  == 3)">已完善</el-tag>
                            <el-tag type="danger" v-else>封禁</el-tag>
                        </template>
                    </el-table-column>

                    <el-table-column fixed="right" label="操作" width="200">
                        <template #default="scope">
                            <el-button v-if="(scope.row.state == 0)" link type="success" size="small" @click="updateUser(scope.row.id,1)">解封</el-button>
                            <el-button v-else link type="danger" size="small" @click="updateUser(scope.row.id,0)">封禁</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <!-- 分页 -->
            <div style="margin-top: 10px; display: flex; justify-content: center;">
                <Pagination :total="total" :page-size="pageSize"  @changePage="changePage"/>
            </div>
        </el-tabs>
    </div>
</template>

<script>
import Pagination from '../components/PageinationView.vue';
import Host from "../store/host";
export default {
    components: {
        Pagination
    },
    data(){
        return {
            tableData: [],
            label: "2",
            tableLoading: false,
            pageSize: 1,
            pageCount: 1,
            total: 0,
            input: "",
        }
       
    },
    mounted() {
       
    },
    created(){
        this.changePage(1);
    },
    methods: {
      // 分页
      changePage(num){
        this.pageCount = num;
        this.getList();
      },
      // 获取列表
      async getList(){
        this.tableLoading = true;
        if(this.label != 0 && this.label != 2) return this.$message({message: '请求失败',type: 'warning'});
        let postUrl = '/api/getUserList?state='+ this.label +'&page=' + this.pageCount;
        let resolveData = await Host.HostGet(postUrl);
        this.tableData = await Host.setList(resolveData.data);
        this.total = resolveData.total
        this.tableLoading = false;

      },
      btnPane(e){
        this.label = e;
        this.changePage(1);
      },
      async updateUser(id,state){
        let tc = this.$message({
            message: '加载中',
            type: 'info',
            duration: 0
        });
        // 提交
        let postUrl = '/api/setUser/update';
        let data = {id: id,state: state};
        let resolveData = await Host.HostPost(postUrl,data);
        tc.close();
        if(resolveData.code !== 200) return this.$message({message: '失败',type: 'warning'});
        this.getList();
        this.$message({message: '修改成功',type: 'success'});
      },
      // 搜索
      async searchBtn(){
        if(this.input.length == 0) return this.$message({message: '内容不能为空',type: 'warning'});
        this.tableLoading = true;
        if(this.label != 0 && this.label != 2) return this.$message({message: '请求失败',type: 'warning'});
        this.pageCount = 1;
        let postUrl = `/api/search/user?value=${this.input}&page=${this.pageCount}`;
        let resolveData = await Host.HostGet(postUrl);
        this.tableLoading = false;
        if(resolveData.code != 200) return this.$message({message: resolveData.msg,type: 'warning'});
        this.tableData = await Host.setList(resolveData.data);
        this.total = resolveData.total
      }
    }
} 
</script>

<style>
    .searchBox {
        display: flex;
    }
     .searchBox .searchBtn {
        margin-left: 20px;
        border-radius: 5px !important;

    }
</style>
